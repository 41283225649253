import React from 'react';

import { Box, Card, CardContent, List, ListItem, Typography } from '@material-ui/core';
import { CheckCircle } from 'react-feather';
import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import Localized from 'components/Localized';
import ListItemText from 'components/mui/ListItemText';
import TextField from 'components/mui/TextField';
import { IUser } from 'core/user';
import { useReduceptForm } from 'hooks/useReduceptForm';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { history, routeCreator, UserRouteParams } from 'services/routing';

import { setLoginError, setUser } from 'store/session/sessionActions';
import { useTypedDispatch, useTypedSelector } from 'store/store';
import { isAxiosError } from 'utils/guard';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

type FetchResponse = IUser;
type Props = RouteComponentProps<UserRouteParams, any, FetchResponse & { qr_code: string }>;

export interface IConfirmTwoFactorAuthPayload {
  code: string;
}

type ErrorResponse = {
  message: string;
};

const ConfirmTwoFactorAuth: React.FC<Props> = (props) => {
  const { userId } = props.match.params;
  const dispatch = useTypedDispatch();
  const loginError = useTypedSelector((state) => state.session.loginError);
  const [recoveryCodes, setRecoveryCodes] = React.useState<string[]>([]);

  const request = useStateOrFetch<FetchResponse | null>(props.location.state, `/users/${userId}`);
  const putRequest = useRequest<{ recovery_codes: string[] }, never, IConfirmTwoFactorAuthPayload>({
    url: `/users/${userId}/tfa`,
    method: 'PUT',
  });

  const onSubmit: SubmitHandler<IConfirmTwoFactorAuthPayload> = async (values) => {
    try {
      const {
        data: { recovery_codes },
      } = await putRequest.request({
        data: values,
      });
      setRecoveryCodes(recovery_codes);
      dispatch(setUser({ ...user, has_tfa_enabled: true, recovery_codes }));
      dispatch(setLoginError(null));
    } catch (error) {
      if (isAxiosError<ErrorResponse>(error)) {
        if (!error.response) {
          dispatch(setLoginError('error_message'));
          return;
        }

        dispatch(setLoginError(error.response.data.message));
      }
    }
  };

  const {
    register,
    errors,
    handleSubmit,
    formState: { touched, isSubmitting },
  } = useReduceptForm<IConfirmTwoFactorAuthPayload>(onSubmit, {});

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const user = request.data as FetchResponse;

  if (user.has_tfa_enabled) {
    history.push(routeCreator.ManageTwoFactorAuth(userId), user);
  }

  const pageTitle = getLocalizedString('enable_tfa');

  const qrCodeSvg = atob(props.history.location.state.qr_code);

  return (
    <DetailPage title={pageTitle} subtitle={user.name} maxWidth='xs'>
      <Card>
        <CardContent>
          {recoveryCodes.length > 0 ? (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              gridGap={7}
              textAlign='center'
              my={1}
            >
              <Box display='flex' justifyContent='center' alignItems='center' gridGap={5}>
                <CheckCircle color='green' />
                <Typography color='textPrimary' variant='h3'>
                  <Localized id='success'>Success</Localized>
                </Typography>
              </Box>

              <Typography color='textSecondary' variant='body2'>
                <Localized id='tfa_enabled_success'>
                  Two factor authentication has been enabled!
                </Localized>
              </Typography>

              <Typography color='textPrimary' variant='h4'>
                <Localized id='recovery_codes'>Recovery codes</Localized>
              </Typography>
              <Localized id='recovery_codes_explanation'>
                <Typography color='textSecondary' variant='body2'>
                  These recovery codes can be used to recover your account in case you've lost
                  access to your 2FA app.
                </Typography>
              </Localized>

              <Localized id='recovery_codes_store_safely' elems={{ bold_text: <b></b> }}>
                <Typography color='textSecondary' variant='body2'>
                  <b>Store them somewhere safe!</b>
                </Typography>
              </Localized>

              <List style={{ display: 'inline-block' }} dense disablePadding>
                {recoveryCodes.map((code) => (
                  <ListItem key={code}>
                    <ListItemText secondary={code} />
                  </ListItem>
                ))}
              </List>
              <Box my={2} display='flex' justifyContent='center'>
                <LinkButton
                  translationKey='back'
                  to={routeCreator.ManageTwoFactorAuth(user.id)}
                  color='primary'
                  disabled={isSubmitting}
                  size='small'
                  type='button'
                  variant='contained'
                >
                  Back
                </LinkButton>
              </Box>
            </Box>
          ) : (
            <Box textAlign='center'>
              <Box mb={2}>
                <Typography color='textPrimary' variant='body2'>
                  <Localized id='scan_qr_code'>
                    Scan the QR code below using an Two-Factor authentication app, for example the
                    Google Authenticator. Afterwards fill in the generated code below.
                  </Localized>
                </Typography>
              </Box>
              <span dangerouslySetInnerHTML={{ __html: qrCodeSvg }} />
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.code && errors.code)}
                  fullWidth
                  helperText={touched.code && errors.code?.message}
                  label='Code'
                  margin='normal'
                  translationKey='code'
                  inputRef={register({ required: getLocalizedString('required') })}
                  type='text'
                  autoComplete='one-time-code'
                  variant='outlined'
                  disabled={isSubmitting}
                />

                {loginError && !isSubmitting ? (
                  <Typography color='error' variant='body1' align='center'>
                    {getLocalizedString(loginError)}
                  </Typography>
                ) : null}

                <Box my={2}>
                  <Button
                    translationKey='enable_tfa'
                    color='primary'
                    loading={isSubmitting}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Enable 2FA
                  </Button>
                </Box>
                <Box my={2} display='flex' justifyContent='center'>
                  <LinkButton
                    translationKey='back'
                    to={routeCreator.EnableTwoFactorAuth(user.id)}
                    color='secondary'
                    disabled={isSubmitting}
                    size='small'
                    type='button'
                    variant='text'
                  >
                    Back
                  </LinkButton>
                </Box>
              </form>
            </Box>
          )}
        </CardContent>
      </Card>
    </DetailPage>
  );
};

export default ConfirmTwoFactorAuth;
