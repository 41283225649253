import { ProfessionalUserRoleType } from 'core/professional';
import { IUser } from 'core/user';
import { Role } from 'services/roles';
import { AppState } from 'store/store';

import { ISessionState } from './sessionReducer';

const hasTokens = (session: ISessionState) => session.token !== null && session.refresh !== null;

/**
 * Checks if the user is logged in.
 *
 * @param {AppState} state
 *   The application state.
 *
 * @return {boolean}
 *   True if logged in.
 */
export const loggedInSelector = ({ session }: AppState): boolean =>
  hasTokens(session) && session.user !== null;

/**
 * The application state is currently in an initial load. This means that
 * necessary data is currently being fetched from the API which is needed to
 * evaluate roles.
 *
 * @param {AppState} session
 *   The application state.
 *
 * @return {boolean}
 *   True if the initial load is going on.
 */
export const onInitialLoadSelector = ({ session }: AppState): boolean => {
  if (!session._persist.rehydrated) {
    return true;
  }

  return (
    hasTokens(session) &&
    (session.user === null || session.roles === null || session.professionals === null)
  );
};

/**
 * Selects the current signed in user's data.
 *
 * @param {AppState} session
 *   The application state.
 *
 * @return {IUser}
 *   User object of the current signed in user.
 *
 * @throws {Error}
 *   If the user is empty. This implies an implementation failure as this should
 *   not be called when no user is available.
 */
export const userSelector = ({ session }: AppState): IUser => {
  if (session.user === null) {
    throw new Error('User object is empty');
  }

  return session.user;
};

/**
 * Checks if the current logged in user is an administrator.
 *
 * @param {AppState} session
 *   The application state.
 *
 * @return {boolean}
 *   True if the user is an administrator.
 */
export const isAdminSelector = ({ session }: AppState): boolean => {
  if (session.roles === null) {
    return false;
  }

  const match = session.roles.find((role) => role.title === 'admin');

  return match !== undefined;
};

/**
 * Checks if the current logged in user is a practice owner.
 *
 * @param {AppState} session
 *   The application state.
 *
 * @return {boolean}
 *   True if the user is a practice owner.
 */
export const isOwnerSelector = (state: AppState): boolean => {
  const match = state.session.professionals?.find(
    (professional) => professional.role === ProfessionalUserRoleType.Owner,
  );

  return match !== undefined;
};

/**
 * Checks if the current logged in user has 2FA enabled
 *
 * @param {AppState} session
 *   The application state.
 *
 * @return {boolean}
 *   True if the user has 2FA enabled
 */
export const hasTfaEnabledSelector = (state: AppState): boolean =>
  state.session.user?.has_tfa_enabled ?? false;

export const matchRoles = (roles: Role[]) => (state: AppState): boolean => {
  const matches: boolean[] = [];

  if (roles.includes(Role.Admin)) {
    matches.push(isAdminSelector(state));
  }

  if (roles.includes(Role.Therapist)) {
    const match = state.session.professionals?.find(
      (professional) => professional.role === ProfessionalUserRoleType.Therapist,
    );

    matches.push(match !== undefined);
  }

  if (roles.includes(Role.Owner)) {
    const match = state.session.professionals?.find(
      (professional) => professional.role === ProfessionalUserRoleType.Owner,
    );

    matches.push(match !== undefined);
  }

  return matches.includes(true);
};
