import { all, call, put, select } from 'redux-saga/effects';

import * as storageKeys from 'constants/storageKeys';
import { LoadedProfessionalUser } from 'core/backend';
import { IProfessional } from 'core/professional';
import { IUser } from 'core/user';
import { apiMethods, setToken, unsetToken } from 'services/http';
import { Role } from 'services/roles';
import { history, routes } from 'services/routing';
import { AppState } from 'store/store';
import { decodeJwt } from 'utils/auth';

import * as sessionActions from './sessionActions';
import { SessionStateProfessionals } from './sessionReducer';
import { matchRoles } from './sessionSelectors';

export function* sync(token: string): Generator<unknown, IUser, IUser> | null {
  setToken(token);
  const decoded = decodeJwt(token);

  try {
    const [users, professionals, roles] = yield all([
      yield call(apiMethods.getUser, decoded.sub),
      yield call(apiMethods.getUserProfessionals, decoded.sub),
      yield call(apiMethods.getUserRoles, decoded.sub),
    ]);

    // Transform the response data of the /users/:id/professionals request so
    // that we only store the essentials in the redux store.
    const transformedProfessionals = professionals.data.data.map(
      (professional: IProfessional & LoadedProfessionalUser): SessionStateProfessionals => ({
        id: professional.id,
        name: professional.name,
        role: professional.professional_user.role,
      }),
    );

    yield all([
      yield put(sessionActions.setUser(users.data)),
      yield put(sessionActions.setProfessionals(transformedProfessionals)),
      yield put(sessionActions.setRoles(roles.data.data)),
    ]);

    return users.data;
  } catch (error) {
    console.error('Recieved an error while trying to fetch the tokens user', error);
    yield put(sessionActions.doLogout);

    return null;
  }
}

export function* uponLogin(
  action: ReturnType<typeof sessionActions.doLogin>,
): Generator<unknown, void, AppState> {
  yield sync(action.payload.token);

  const state: AppState = yield select();
  const userCanLogin = matchRoles([Role.Admin, Role.Therapist, Role.Owner])(state);

  if (userCanLogin) {
    history.push(routes.Dashboard);
  } else {
    yield put(sessionActions.doLogout);
    yield put(sessionActions.setLoginError('login_incorrect_role_error'));
  }
}

export function uponLogout(): void {
  unsetToken();

  // Clear the user's language preference from localStorage
  localStorage.removeItem(storageKeys.dashboardLanguage);

  history.push(routes.Login);
}
