import React from 'react';

import {
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Lock as LockIcon } from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button';
import CheckIcon from 'components/CheckIcon';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import { IUser } from 'core/user';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { routeCreator, UserRouteParams } from 'services/routing';

import { isAdminSelector, userSelector } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

type FetchResponse = IUser;
type Props = RouteComponentProps<UserRouteParams, any, FetchResponse>;

const ManageTwoFactorAuth: React.FC<Props> = (props) => {
  const { userId } = props.match.params;
  const [recoveryCodes, setRecoveryCodes] = React.useState<string[]>([]);
  const authenticatedUser = useTypedSelector(userSelector);
  const isAdmin = useTypedSelector(isAdminSelector);

  const request = useStateOrFetch<FetchResponse | null>(props.location.state, `/users/${userId}`);

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const user = request.data as FetchResponse;
  const isSelf = authenticatedUser.id === user.id;

  const pageTitle = getLocalizedString('tfa');

  const handleShowRecoveryCodes = () => {
    if (user.recovery_codes) {
      setRecoveryCodes(user.recovery_codes);
    }
  };

  const handleHideRecoveryCodes = () => {
    setRecoveryCodes([]);
  };

  return (
    <DetailPage title={pageTitle} subtitle={user.name} maxWidth='xs'>
      <Card>
        <CardContent>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            gridGap={5}
            textAlign='center'
          >
            <List dense disablePadding>
              <Typography
                color='textPrimary'
                variant='body2'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {getLocalizedString('tfa_enabled')}
                <CheckIcon condition={user.has_tfa_enabled} />
              </Typography>
              {isAdmin && isSelf && !user.has_tfa_enabled && (
                <Typography color='error' variant='body2'>
                  {getLocalizedString('tfa_should_be_enabled')}
                </Typography>
              )}
              {user.has_tfa_enabled ? (
                <>
                  {recoveryCodes?.length ? (
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                      gridGap={7}
                      textAlign='center'
                      my={1}
                    >
                      <List style={{ display: 'inline-block' }} dense disablePadding>
                        {recoveryCodes.map((code) => (
                          <ListItem key={code}>
                            <ListItemText secondary={code} />
                          </ListItem>
                        ))}
                      </List>
                      <Button
                        translationKey='hide_recovery_codes'
                        color='secondary'
                        variant='contained'
                        onClick={handleHideRecoveryCodes}
                        disabled={!isSelf}
                      >
                        Hide recovery codes
                      </Button>
                    </Box>
                  ) : (
                    <Box my={2} display='flex' justifyContent='center'>
                      <Button
                        translationKey='show_recovery_codes'
                        color='secondary'
                        variant='outlined'
                        onClick={handleShowRecoveryCodes}
                        disabled={!isSelf}
                      >
                        Show recovery codes
                      </Button>
                    </Box>
                  )}
                  <Box my={2} display='flex' justifyContent='center'>
                    <LinkButton
                      translationKey='disable_tfa'
                      to={routeCreator.DisableTwoFactorAuth(user.id)}
                      state={user}
                      color='secondary'
                      variant='outlined'
                      disabled={!isAdmin && !isSelf}
                    >
                      Disable 2FA
                    </LinkButton>
                  </Box>
                </>
              ) : (
                <>
                  <Box my={2} display='flex' justifyContent='center'>
                    <LinkButton
                      translationKey='enable_tfa'
                      to={routeCreator.EnableTwoFactorAuth(user.id)}
                      state={user}
                      startIcon={<LockIcon size={18} />}
                      color='primary'
                      variant='contained'
                      style={{ marginLeft: 4 }}
                      disabled={!isSelf}
                    >
                      Enable 2FA
                    </LinkButton>
                  </Box>
                </>
              )}
            </List>
          </Box>
        </CardContent>
      </Card>
    </DetailPage>
  );
};

export default ManageTwoFactorAuth;
