import React from 'react';

import { Box, Card, colors, Container, makeStyles, Typography } from '@material-ui/core';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';

import Button from 'components/Button';
import LinkButton from 'components/LinkButton';
import Localized from 'components/Localized';
import TextField from 'components/mui/TextField';
import Page from 'components/Page';
import { apiMethods } from 'services/http';
import { useLocalization } from 'services/localization/localization';
import { routes } from 'services/routing';
import { doLogin, setLoginError } from 'store/session/sessionActions';
import { loggedInSelector, onInitialLoadSelector } from 'store/session/sessionSelectors';
import { useTypedDispatch } from 'store/store';
import { useTypedSelector } from 'store/store';
import { darkBackground } from 'theme/theme';
import { setUnprocessableEntityErrors } from 'utils/forms';
import { isAxiosError } from 'utils/guard';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: darkBackground,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  card: {
    padding: theme.spacing(3),
    borderWidth: 8,
    borderColor: colors.common.white,
    hoverBorderColor: colors.common.white,
  },
}));

interface IFormValues {
  email: string;
  password: string;
  tfa_code?: string;
  recovery_code?: string;
}

type ErrorResponse = {
  message: string;
};

const Login: React.FC = () => {
  const loggedIn = useTypedSelector(loggedInSelector);
  const loginError = useTypedSelector((state) => state.session.loginError);
  const onInitialLoad = useTypedSelector(onInitialLoadSelector);
  const classes = useStyles();
  const dispatch = useTypedDispatch();
  const { getLocalizedString } = useLocalization();

  const [requiresTfa, setRequiresTfa] = React.useState(false);
  const [requiresTfaRecoveryCode, SetRequiresTfaRecoveryCode] = React.useState(false);

  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState: { touched, isSubmitting, isValid },
  } = useForm<IFormValues>({ mode: 'onChange' });

  if (loggedIn) {
    return <Redirect to={routes.Dashboard} />;
  }

  const onSubmit: SubmitHandler<IFormValues> = async (values) => {
    try {
      const response = await apiMethods.login({
        email: values.email,
        password: values.password,
        tfa_code: values.tfa_code,
        recovery_code: values.recovery_code,
      });

      dispatch(doLogin(response.data));
      dispatch(setLoginError(null));
    } catch (error) {
      if (isAxiosError<ErrorResponse>(error)) {
        if (!error.response) {
          return;
        }

        let message = error.response.data.message;

        if (message === 'missing-tfa-code') {
          setRequiresTfa(true);
          return;
        }

        if (error.response.status === 401) {
          message = 'incorrect_credentials';
        }

        if (error.response.status === 422) {
          setUnprocessableEntityErrors<IFormValues>(error as any, setError);
        }

        dispatch(setLoginError(message));
      }
    }
  };

  const handleUseTfaRecoveryCodeClick = () => {
    SetRequiresTfaRecoveryCode(true);
  };

  const handleHideTfaRecoveryCodeClick = () => {
    SetRequiresTfaRecoveryCode(false);
  };

  return (
    <Page className={classes.root} title='Login'>
      <Box display='flex' flexDirection='column' height='100%' justifyContent='center'>
        <Container maxWidth='sm'>
          <Card className={classes.card}>
            <Typography color='textPrimary' variant='h2'>
              {getLocalizedString('sign-in')}
            </Typography>
            <Typography color='textSecondary' gutterBottom variant='body2'>
              <Localized id='login-with-your-account'>Login with your Reducept account</Localized>
            </Typography>

            <Box mt={2}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email?.message}
                  label='Email Address'
                  margin='normal'
                  translationKey='email'
                  inputRef={register({ required: getLocalizedString('required') })}
                  type='email'
                  autoComplete='email'
                  variant='outlined'
                  disabled={isSubmitting || onInitialLoad}
                  InputProps={{ readOnly: requiresTfa }}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password?.message}
                  label='Password'
                  margin='normal'
                  translationKey='password'
                  inputRef={register({ required: getLocalizedString('required') })}
                  type='password'
                  autoComplete='password'
                  variant='outlined'
                  disabled={isSubmitting || onInitialLoad}
                  InputProps={{ readOnly: requiresTfa }}
                />

                {requiresTfa && (
                  <>
                    <Typography color='textSecondary' gutterBottom variant='body2'>
                      <Localized id='check-tfa-app'>
                        Check your authenticator app for a code
                      </Localized>
                    </Typography>

                    <TextField
                      error={Boolean(touched.tfa_code && errors.tfa_code)}
                      fullWidth
                      helperText={touched.tfa_code && errors.tfa_code?.message}
                      label='Code'
                      margin='normal'
                      translationKey='code'
                      inputRef={register({ required: getLocalizedString('required') })}
                      type='text'
                      autoComplete='one-time-code'
                      name='tfa_code'
                      variant='outlined'
                      disabled={isSubmitting}
                    />
                  </>
                )}

                {requiresTfaRecoveryCode && (
                  <>
                    <Typography color='textSecondary' gutterBottom variant='body2'>
                      <Localized id='use_tfa_recovery_code_explanation'>
                        Use one of your saved recovery codes
                      </Localized>
                    </Typography>

                    <TextField
                      error={Boolean(touched.recovery_code && errors.recovery_code)}
                      fullWidth
                      helperText={touched.recovery_code && errors.recovery_code?.message}
                      label='Recovery code'
                      margin='normal'
                      translationKey='recovery_code'
                      inputRef={register({ required: getLocalizedString('required') })}
                      type='text'
                      name='recovery_code'
                      variant='outlined'
                      disabled={isSubmitting}
                    />
                  </>
                )}

                {loginError && !isSubmitting ? (
                  <Typography color='error' variant='body1' align='center'>
                    {getLocalizedString(loginError)}
                  </Typography>
                ) : null}

                <Box my={2}>
                  <Button
                    translationKey='login'
                    color='primary'
                    loading={isSubmitting || onInitialLoad}
                    disabled={!isValid || onInitialLoad}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Login
                  </Button>
                </Box>
                <Box display='flex' flexDirection='column' justifyContent='center' gridGap={5}>
                  <LinkButton
                    translationKey='forgot-password'
                    to={routes.PasswordReset}
                    color='secondary'
                    disabled={isSubmitting || onInitialLoad}
                    size='small'
                    type='button'
                    variant='text'
                  >
                    Forgot password?
                  </LinkButton>
                  {requiresTfaRecoveryCode ? (
                    <Button
                      translationKey='hide_tfa_recovery_code'
                      color='secondary'
                      onClick={handleHideTfaRecoveryCodeClick}
                      disabled={isSubmitting || onInitialLoad}
                      size='small'
                      type='button'
                      variant='text'
                    >
                      Hide 2FA recovery code
                    </Button>
                  ) : (
                    <Button
                      translationKey='use_tfa_recovery_code'
                      color='secondary'
                      onClick={handleUseTfaRecoveryCodeClick}
                      disabled={isSubmitting || onInitialLoad}
                      size='small'
                      type='button'
                      variant='text'
                    >
                      Use 2FA recovery code
                    </Button>
                  )}
                </Box>
              </form>
            </Box>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default Login;
