import React from 'react';

import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import TextField from 'components/mui/TextField';
import { IUser } from 'core/user';
import { useReduceptForm } from 'hooks/useReduceptForm';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { history, routeCreator, UserRouteParams } from 'services/routing';

import { setLoginError } from 'store/session/sessionActions';
import { useTypedDispatch, useTypedSelector } from 'store/store';
import { isAxiosError } from 'utils/guard';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

type FetchResponse = IUser;
type Props = RouteComponentProps<UserRouteParams, any, FetchResponse>;

interface IEnableTwoFactorAuthPayload {
  password: string;
}

type ErrorResponse = {
  message: string;
};

const EnableTwoFactorAuth: React.FC<Props> = (props) => {
  const { userId } = props.match.params;
  const dispatch = useTypedDispatch();
  const loginError = useTypedSelector((state) => state.session.loginError);
  const { getLocalizedString } = useLocalization();

  const request = useStateOrFetch<FetchResponse | null>(props.location.state, `/users/${userId}`);
  const postRequest = useRequest<{ qr_code: string }, never, IEnableTwoFactorAuthPayload>({
    url: `/users/${userId}/tfa`,
    method: 'POST',
  });

  React.useEffect(() => {
    dispatch(setLoginError(null));
  }, []);

  const onSubmit = async (values: IEnableTwoFactorAuthPayload, user: IUser) => {
    try {
      const {
        data: { qr_code },
      } = await postRequest.request({
        data: values,
      });

      history.push(routeCreator.ConfirmTwoFactorAuth(userId), {
        ...user,
        qr_code,
      });
      dispatch(setLoginError(null));
    } catch (error) {
      if (isAxiosError<ErrorResponse>(error)) {
        if (!error.response) {
          dispatch(setLoginError('error_message'));
          return;
        }

        dispatch(setLoginError(error.response.data.message));
      }
    }
  };

  const {
    register,
    errors,
    handleSubmit,
    formState: { touched, isSubmitting },
  } = useReduceptForm<IEnableTwoFactorAuthPayload>((data) => onSubmit(data, user), {});

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const user = request.data as FetchResponse;

  if (user.has_tfa_enabled) {
    history.push(routeCreator.ManageTwoFactorAuth(userId), user);
  }

  const pageTitle = getLocalizedString('enable_tfa');

  return (
    <DetailPage title={pageTitle} subtitle={user.name} maxWidth='xs'>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password?.message}
              label='Password'
              margin='normal'
              translationKey='password'
              inputRef={register({ required: getLocalizedString('required') })}
              type='password'
              autoComplete='password'
              variant='outlined'
              disabled={isSubmitting}
            />

            {loginError && !isSubmitting ? (
              <Typography color='error' variant='body1' align='center'>
                {getLocalizedString(loginError)}
              </Typography>
            ) : null}

            <Box my={2}>
              <Button
                translationKey='next'
                color='primary'
                loading={isSubmitting}
                fullWidth
                size='large'
                type='submit'
                variant='contained'
              >
                Next
              </Button>
            </Box>
            <Box my={2} display='flex' justifyContent='center'>
              <LinkButton
                translationKey='back'
                to={routeCreator.ManageTwoFactorAuth(user.id)}
                color='secondary'
                disabled={isSubmitting}
                size='small'
                type='button'
                variant='text'
              >
                Back
              </LinkButton>
            </Box>
          </form>
        </CardContent>
      </Card>
    </DetailPage>
  );
};

export default EnableTwoFactorAuth;
