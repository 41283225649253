import React from 'react';

import { Grid, Tooltip } from '@material-ui/core';
import { DateTime } from 'luxon';
import {
  Edit2 as EditIcon,
  Key as PasswordIcon,
  Unlock as ResetPasswordIcon,
  Lock as LockIcon,
} from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button';
import ButtonBar from 'components/ButtonBar';
import CommentsCard from 'components/comments/CommentsCard';
import DateRangePicker from 'components/daterangepicker/DateRangePicker';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import RoleBoundary from 'components/RoleBoundary';
import { LoadedProfessionalUser } from 'core/backend';
import { IUser } from 'core/user';
import useFetch from 'hooks/useFetch';
import { useLocalization } from 'services/localization/localization';
import { Role } from 'services/roles';
import { ProfessionalRouteParams, routeCreator, UserRouteParams } from 'services/routing';
import { isAdminSelector, isOwnerSelector, matchRoles } from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';

import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

import CardUserAccessPeriods from './components/CardUserAccessPeriods';
import CardUserDetails from './components/CardUserDetails';
import CardUserDevices from './components/CardUserDevices';
import CardUserEcurringCustomer from './components/CardUserEcurringCustomer';
import CardUserLogs from './components/CardUserLogs';
import CardUserMailchimpMember from './components/CardUserMailchimpMember';
import CardUserMailchimpTags from './components/CardUserMailchimpTags';
import CardUserMetaData from './components/CardUserMetaData';
import CardUserPainScores from './components/CardUserPainScores';
import CardUserPainScoresImage from './components/CardUserPainScoresImage';
import CardUserPlayerAccounts from './components/CardUserPlayerAccounts';
import CardUserProfessionals from './components/CardUserProfessionals';
import CardUserRoles from './components/CardUserRoles';
import CardUserStripeCustomer from './components/CardUserStripeCustomer';
import CardUserSubscriptions from './components/CardUserSubscriptions';
import CardUserWellbeingScores from './components/CardUserWellbeingScores';
import SendPasswordResetEmailDialog from './components/SendPasswordResetEmailDialog';

type RouteParams = UserRouteParams & Partial<ProfessionalRouteParams>;
type Props = RouteComponentProps<RouteParams, any, IUser | undefined>;

const UserDetail: React.FC<Props> = (props) => {
  const { userId } = props.match.params;
  const isAdmin = useTypedSelector(isAdminSelector);
  const isTherapistOrOwner = useTypedSelector(matchRoles([Role.Therapist, Role.Owner]));
  const { getLocalizedString } = useLocalization();

  const request = useFetch<IUser>(`/users/${userId}`);

  const [startDate, setStartDate] = React.useState<DateTime | null>(null);
  const [endDate, setEndDate] = React.useState<DateTime | null>(null);
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = React.useState(false);

  const handleDatesChanged = (startDate: DateTime | null, endDate: DateTime | null) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  // Can contain professional_user if we navigated here from ProfessionalPatientsOverview
  const user = request.data as IUser & Partial<LoadedProfessionalUser>;

  const passwordResetTooltip = !user.has_password
    ? getLocalizedString('reset-password-tooltip-user-has-no-password')
    : '';

  const therapistOwnerAdminActions: JSX.Element[] =
    isTherapistOrOwner || isAdmin
      ? [
          <LinkButton
            translationKey='2fa'
            to={routeCreator.ManageTwoFactorAuth(user.id)}
            startIcon={<LockIcon size={18} />}
            color='primary'
            variant='outlined'
            style={{ marginLeft: 4 }}
          >
            2FA
          </LinkButton>,
        ]
      : [];

  // Only admins can change user passwords and edit their data
  const actions: JSX.Element[] = isAdmin
    ? [
        <Tooltip enterDelay={300} title={passwordResetTooltip}>
          <div>
            <Button
              translationKey='reset-password'
              color='secondary'
              variant='contained'
              startIcon={<ResetPasswordIcon size={18} />}
              onClick={() => setResetPasswordDialogOpen(true)}
              disabled={!user.has_password}
              style={{ marginLeft: 4, whiteSpace: 'nowrap' }}
            >
              Reset password
            </Button>
          </div>
        </Tooltip>,

        <LinkButton
          translationKey='change-password'
          to={routeCreator.ChangeUserPassword(user.id)}
          state={user}
          startIcon={<PasswordIcon size={18} />}
          color='primary'
          variant='outlined'
          style={{ marginLeft: 4 }}
        >
          Change password
        </LinkButton>,
        <LinkButton
          translationKey='edit'
          to={routeCreator.EditUser(user.id)}
          state={user}
          startIcon={<EditIcon size={18} />}
          color='primary'
          variant='contained'
          style={{ marginLeft: 4 }}
        >
          Edit
        </LinkButton>,
      ]
    : [
        <LinkButton
          translationKey='edit'
          to={routeCreator.EditUser(user.id)}
          state={user}
          startIcon={<EditIcon size={18} />}
          color='primary'
          variant='contained'
          style={{ marginLeft: 4 }}
        >
          Edit
        </LinkButton>,
      ];

  return (
    <DetailPage
      title={user.name ?? getLocalizedString('user')}
      actions={
        <ButtonBar actions={therapistOwnerAdminActions.concat(actions)}>
          <DateRangePicker onChange={handleDatesChanged} />
        </ButtonBar>
      }
    >
      <Grid container spacing={3}>
        <RoleBoundary allowed={[Role.Admin]}>
          <Grid item xs={12}>
            <CommentsCard endpointUrl={`/users/${user.id}/comments`} entity='user' />
          </Grid>
        </RoleBoundary>

        <Grid item xs={12} md={3} lg={3} xl={2}>
          <CardUserDetails user={user} />
        </Grid>
        <Grid item xs={12} md={9} lg={9} xl={7}>
          <CardUserWellbeingScores user={user} startDate={startDate} endDate={endDate} />
        </Grid>
        <Grid item xs={12} md={4} lg={4} xl={3}>
          <CardUserPainScoresImage user={user} startDate={startDate} endDate={endDate} />
        </Grid>

        <RoleBoundary allowed={[Role.Admin]}>
          <Grid item xs={12} md={3} lg={3} xl={2}>
            <CardUserMetaData user={user} />
          </Grid>
          <Grid item xs={12} md={5} lg={5} xl={5}>
            <CardUserSubscriptions user={user} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={5}>
            <CardUserProfessionals user={user} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <CardUserPlayerAccounts user={user} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <CardUserDevices user={user} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <CardUserAccessPeriods user={user} />
          </Grid>
          <Grid item xs={12} md={12} lg={8} xl={6}>
            <CardUserLogs user={user} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <CardUserMailchimpMember userId={user.id} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <CardUserMailchimpTags userId={user.id} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <CardUserStripeCustomer userId={user.id} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} xl={4}>
            <CardUserEcurringCustomer userId={user.id} />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <CardUserRoles userId={user.id} />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <CardUserPainScores user={user} startDate={startDate} endDate={endDate} />
          </Grid>
        </RoleBoundary>
      </Grid>
      <SendPasswordResetEmailDialog
        open={resetPasswordDialogOpen}
        onClose={() => setResetPasswordDialogOpen(false)}
        onEmailSent={() => setResetPasswordDialogOpen(false)}
        user={user}
      />
    </DetailPage>
  );
};

export default UserDetail;
