import { FluentResource } from '@fluent/bundle';

export default new FluentResource(`
## General

-brand-name = Reducept
-ecurring = eCurring
-stripe = Stripe
-mailchimp = MailChimp
-appsflyer = AppsFlyer
-google_analytics = Google Analytics
-support_link = support@reducept.com
support_link = { -support_link }
website_support_link = { $locale ->
  [nl] https://www.reducept.com/nl/support
  [de] https://www.reducept.com/de/support
  *[other] https://www.reducept.com/support
}

## Selectors/Terms

-user = { $amount ->
  *[one] { $casing ->
    *[upper] User
    [lower] user
  }
  [other] { $casing ->
    *[upper] Users
    [lower] users
  }
}

-practice = { $amount ->
  *[one] { $casing ->
    *[upper] Practice
    [lower] practice
  }
  [other] { $casing ->
    *[upper] Practices
    [lower] practices
  }
}

-partner = { $amount ->
  *[one] { $casing ->
    *[upper] Partner
    [lower] partner
  }
  [other] { $casing ->
    *[upper] Partners
    [lower] partners
  }
}

-player-account = { $amount ->
  *[one] { $casing ->
    *[upper] Player account
    [lower] player account
  }
  [other] { $casing ->
    *[upper] Player accounts
    [lower] player accounts
  }
}

-device = { $amount ->
  *[one] { $casing ->
    *[upper] Device
    [lower] device
  }
  [other] { $casing ->
    *[upper] Devices
    [lower] devices
  }
}

-subscription = { $amount ->
  *[one] { $casing ->
    *[upper] Subscription
    [lower] subscription
  }
  [other] { $casing ->
    *[upper] Subscriptions
    [lower] subscriptions
  }
}

-subscription_type = { $amount ->
  *[one] { $casing ->
    *[upper] Subscription type
    [lower] subscription type
  }
  [other] { $casing ->
    *[upper] Subscription types
    [lower] subscription types
  }
}

-role = { $amount ->
  *[one] { $casing ->
    *[upper] Role
    [lower] role
  }
  [other] { $casing ->
    *[upper] Roles
    [lower] roles
  }
}

-administrator = { $amount ->
  *[one] { $casing ->
    *[upper] Administrator
    [lower] administrator
  }
  [other] { $casing ->
    *[upper] Administrators
    [lower] administrators
  }
}

-patient = { $amount ->
  *[one] { $casing ->
    *[upper] Patient
    [lower] patient
  }
  [other] { $casing ->
    *[upper] Patients
    [lower] patients
  }
}

-therapist = { $amount ->
  *[one] { $casing ->
    *[upper] Therapist
    [lower] therapist
  }
  [other] { $casing ->
    *[upper] Therapists
    [lower] therapist
  }
}

-owner = { $amount ->
  *[one] { $casing ->
    *[upper] Owner
    [lower] owner
  }
  [other] { $casing ->
    *[upper] Owners
    [lower] owners
  }
}

-group = { $amount ->
  *[one] { $casing ->
    *[upper] Group
    [lower] group
  }
  [other] { $casing ->
    *[upper] Groups
    [lower] groups
  }
}

-license = { $amount ->
  *[one] { $casing ->
    *[upper] License
    [lower] license
  }
  [other] { $casing ->
    *[upper] Licenses
    [lower] licenses
  }
}

-access_period = { $amount ->
  *[one] { $casing ->
    *[upper] Access period
    [lower] access period
  }
  [other] { $casing ->
    *[upper] Access periods
    [lower] access periods
  }
}

-professional_invite = { $amount ->
  *[one] { $casing ->
    *[upper] Invite
    [lower] invite
  }
  [other] { $casing ->
    *[upper] Invites
    [lower] invites
  }
}

-password = { $casing ->
  *[upper] Password
  [lower] password
}

-email = { $casing ->
  *[upper] Email
  [lower] email
}

-ecurring_customer = { $amount ->
  *[one] { -ecurring } customer
  [other] { -ecurring } customers
}

-stripe_account = { $amount ->
  *[one] { -stripe } account
  [other] { -stripe } accounts
}

-mailchimp_account = { $amount ->
  *[one] { -mailchimp } account
  [other] { -mailchimp } accounts
}

-mailchimp_tag = { $amount ->
  *[one] { -mailchimp } tag
  [other] { -mailchimp } tags
}

-log = { $amount ->
  *[one] { $casing ->
    *[upper] Log
    [lower] log
  }
  [other] { $casing ->
    *[upper] Logs
    [lower] logs
  }
}

-pain_score = { $amount ->
  *[one] { $casing ->
    *[upper] Painscore
    [lower] painscore
  }
  [other] { $casing ->
    *[upper] Painscores
    [lower] painscores
  }
}

-wellbeing_score = { $amount ->
  *[one] { $casing ->
    *[upper] Wellbeing score
    [lower] wellbeing score
  }
  [other] { $casing ->
    *[upper] Wellbeing scores
    [lower] wellbeing scores
  }
}

-pain_location = { $amount ->
  *[one] { $casing ->
    *[upper] Pain location
    [lower] pain location
  }
  [other] { $casing ->
    *[upper] Pain locations
    [lower] pain locations
  }
}

-game_chapter = { $amount ->
  *[one] { $casing ->
    *[upper] Chapter
    [lower] chapter
  }
  [other] { $casing ->
    *[upper] Chapters
    [lower] chapters
  }
}

-comment = { $amount ->
  *[one] { $casing ->
    *[upper] Comment
    [lower] comment
  }
  [other] { $casing ->
    *[upper] Comments
    [lower] comments
  }
}

## Models

user = { -user }
users = { -user(amount: 2) }

practice = { -practice }
practices = { -practice(amount: 2) }
professional = { -practice }
professionals = { -practice(amount: 2) }

partner = { -partner }
partners = { -partner(amount: 2) }

organization = { partner }
organizations = { partners }

player_account = { -player-account }
player_accounts = { -player-account(amount: 2) }
player_account_for = { -player-account } for

device = { -device }
devices = { -device(amount: 2) }

subscription = { -subscription }
subscription_of_model = { -subscription } of { $model }
subscriptions = { -subscription(amount: 2) }

subscription_type = { -subscription_type }
subscription_types = { -subscription_type(amount: 2) }
provider_type = { -subscription_type }
provider_types = { -subscription_type(amount: 2) }

role = { -role }
roles = { -role(amount: 2) }

administrator = { -administrator }
administrators = { -administrator(amount: 2) }
admin = { -administrator }

patient = { -patient }
patients = { -patient(amount: 2) }

therapist = { -therapist }
therapists = { -therapist(amount: 2) }

owner = { -owner }
owners = { -owners(amount: 2) }

group = { -group }
groups = { -group(amount: 2) }

access_period = { -access_period }
access_periods = { -access_period(amount: 2) }

professional_invite = { -professional_invite }
professional_invites = { -professional_invite(amount: 2) }

staff = Staff

ecurring_customer = { -ecurring_customer }
ecurring_customers = { -ecurring_customer(amount: 2) }

stripe_account = { -stripe_account }
stripe_accounts = { -stripe_account(amount: 2) }

mailchimp_account = { -mailchimp_account }
mailchimp_accounts = { -mailchimp_account(amount: 2) }

mailchimp_tag = { -mailchimp_tag }
mailchimp_tags = { -mailchimp_tag(amount: 2) }

log = { -log }
logs = { -log(amount: 2) }

pain_score = { -pain_score }
pain_scores = { -pain_score(amount: 2) }

wellbeing_score = { -wellbeing_score }
wellbeing_scores = { -wellbeing_score(amount: 2) }

pain_location = { -pain_location }
pain_locations = { -pain_location(amount: 2) }

game_chapter = { -game_chapter }
game_chapters = { -game_chapter(amount: 2) }

comment = { -comment }
comments = { -comment(amount: 2) }

## Menu items

dashboard = Dashboard

## Buttons

add = Add
new = New
edit = Edit
save = Save
delete = Delete
cancel = Cancel
submit = Submit
change-password = Change password
invite-patient-button = Invite { -patient(casing: "lower") }
invite-staff-button = Invite staff member
import = Import
export = Export
search = Search
search-model = Search { $model }
extend-access = Extend access
extend-by-3-months = Extend access by 3 months
login = Log in
logout = Log out
accept = Accept
resend = Resend
tfa = 2FA
show_recovery_codes = Show recovery codes
hide_recovery_codes = Hide recovery codes
back = Back
next = Next

## Model specific

add-model = Add { $model }
update-model = Edit { $model }
delete-model = Delete { $model }
not-found = No { $model } found
general-data = General { $model } information

------

## Fields used throughout the Dashboard

roleId = Role
created_at = Created at
added_at = Added at
updated_at = Updated at
synced_at = Synced at
synced_at_description = The last time that a synchronization was done with the external payment provider. This is only available for { -stripe } and { -ecurring } { -subscription(amount: 2, casing: "lower") }
since = Since
created_by = Created by
id = Id
password = { -password }
password_confirmation = Confirm { -password(casing: "lower") }
password_changed_success = { -password } has changed
email = { -email }
first_name = First name
middle_name = Middle name
last_name = Last name
language = Language
name = Name
has_access = Has access
has_access_description = The { -patient(casing: "lower") } has access to { -brand-name }
active = Active
active_subscription_id = { active }
active_subscription_id_description = This { -practice(casing: "lower") } Has an active { -subscription(casing: "lower") }
subscription_professional_owner_expired =  The { -subscription(casing: "lower") } of { $professional_name } has expired
subscription_professional_therapist_expired =  The { -subscription(casing: "lower") } of { $professional_name } has expired. Please contact the { -practice(casing: "lower") } { -owner(casing: "lower") }
access_until = Access until
access_until_description = The { -patient(casing: "lower") } has access until this date
groups_description = The { -group(amount: 2, casing: "lower") } where the { -patient(casing: "lower") } belongs to
user_groups = The { -group(amount: 2, casing: "lower") } where the { -user(casing: "lower") } belongs to
practice_table_tooltip = This { -practice(casing: "lower") } has an unlimited amount of licenses
practice_id = Practice
practice_owner = { -practice(casing: "upper") } owner
practice_owner_description = The user that should be the owner of the { -practice(casing: "lower") }. You can also create a non existing user by clicking 'New'
professionals_count = { -practice(amount: 2, casing: "upper") } count
practice_id_description = The { -practice(casing: "lower") } that is affected by the { -subscription(casing: "lower") }
professional_group = { group } (optional)
professional_group_description = The { -group(casing: "lower") }(s) where this { -patient(casing: "lower") } belongs to. This is optional
professional_groups = { -group(amount: 2, casing:"upper") } (optional)
professional_groups_description = The { -group(amount: 2, casing: "lower") }(s) where this { -patient(casing: "lower") } belongs to. This is optional
professional_group_users = { -user(amount: 2) }
professional_group_users_count = { -user(amount: 2, casing: "upper") } count
practices_table_tooltip = This { -practice(amount:2, casing: "lower") } has an unlimited amount of licenses
associated_practice = Associated { -practice(casing: "lower") }
associated_practices = Associated { -practice(amount: 2, casing: "lower") }
associated_practices_description = The { -practice(amount: 2, casing: "lower") } where the { $model } belongs to
associated_user = Associated { -user(casing: "lower") }
associated_group_users = The associated { -user(amount: 2, casing: "lower") }
unclaimed = Administered by { -therapist(casing: "lower") }
unclaimed_description = This { -user(casing: "lower") } is a { -patient(casing: "lower") } without its own { -brand-name } account. The { -practice(casing: "lower") } administers the account
email_verified_at = Verified at
email_verified_at_description = The { -user(casing: "lower") } has its { -email(casing: "lower") } verified
last_activity_at = Last activity at
has_password = Has password
enable_tfa = Enable 2FA
disable_tfa = Disable 2FA
has_tfa = Has 2FA
tfa_enabled = 2FA enabled
code = Code
recovery_code = Recovery code
practice_description = The desired { -practice(casing: "lower") } to add a { -subscription(casing: "lower") } to
professional_description = { practice_description }
user_description = The desired { -user(casing: "lower") } to add a { -subscription(casing: "lower") } to
patient_description = This { -user(casing: "lower") } is a { -patient(casing: "lower") }
home_usage = Home usage enabled
home_usage_description = Enabling home usage allows { -patient(amount: 2, casing: "lower") } to use the { -device(casing: "lower", amount: 2) } of your { -practice(casing: "lower") } when signing in themselves. Only available for { -user(casing: "lower", amount: 2) } with an { -email(casing: "lower") }.
home_usage_practice_description = This { -user(casing: "lower") } can use this { -device(amount: 2, casing: "lower") } from the { -practice(casing: "lower") } to log in
patient_license_note = Note: when creating this { -patient(casing: "lower") }, a license is automatically used to grant this { -patient(casing: "lower") } access to { -brand-name }. You currently have { $model} licenses left during this period
patient_license_note_unlimited = Note: when creating this { -patient(casing: "lower") }, a license is automatically used to grant this { -patient(casing: "lower") } access to { -brand-name }
redeem_access_period = Activate { -patient(casing: "lower") } now
redeem_access_period_description = An access period will be started for this { -patient(casing: "lower") } after creation. A license will be redeemed.
start_date = Start date
start_date_description = The date when the { -subscription(casing: "lower") } will start. This date can not be changed if the { -subscription(casing: "lower") } has already started
end_date = End date
end_date_description = The date when the { -subscription(casing: "lower") } ends. Can be left empty.
date = Date
minimum_length = Minimum length: { $length }
period_start = Start of the period
period_start_description = The date when the { -access_period(casing: "lower") } starts
period_end = End of the period
period_end_description = The date when the { -access_period(casing: "lower") } ends
current_period_start = Start of the current period
current_period_end = End of the current period
paused_at = Paused at
resumed_at = Will resume at
resume_date = Resumed at
cancelled_at = Cancelled at
cancel_date = { cancelled_at }
cancel_at = Will be canceled at
ended_at = Ended at
staff_therapist_role = Staff members can only be { -therapist(amount: 2, casing: "lower") }
staff_description = This { -user(casing: "lower") } is a staff member within the { -practice(casing: "lower") }
access_periods_used_by = The { -access_period(amount: 2, casing: "lower") } that are used by the { $model }
devices_description = The { -device(amount: 2, casing: "lower") } that are used by the { -user(casing: "lower") }
information = Information
device_type = Type
device_model = Model
device_login_information = { -device } log in information
login_patient_on_device = The { -patient(casing: "lower") } that will be logged in on this { -device(casing:"lower") }. Game data will be saved to this { -patient(casing: "lower") }'s account
login-patient = Log { -patient(casing: "lower") } in on this { -device(casing:"lower") }
operating_system = Operating system
operating_system_family = Operating system family
ecurring_customer_id = { -ecurring_customer } id
ecurring_customer_description = The associated { -ecurring } account
ecurring_plan_id = { -ecurring } plan id
ecurring_id = { -ecurring } id
archived = Archived in { -ecurring }
type = Type
context = Context
message = Message
logs_for_model = { -log(amount: 2, casing: "upper") } tracked for this { $model }
mailchimp_account_description = The associated { -mailchimp_account }
link_to_mailchimp_website = Link to { -mailchimp } website
mailchimp_id = { -mailchimp } id
status = Status
status_description = The status of the { -subscription(casing: "lower") } inside the paymentprovider
last_changed = { updated_at }
timestamp_opt = { created_at }
unsubscribe_reason = Unsubscribe reason
mailchimp_tags_description = The associated { -mailchimp_tag(amount: 2) }
title = Title
meta_data = Meta data
meta_data_description = Administrative data
ga_cid = { -google_analytics } id
exclude_reports = Excluded from reports
appsflyer_cuid = { -appsflyer } id
registration_origin = Registration origin
personal = Personal
average = Average
measured_pain_scores = Statistics of { $username }'s measured { -pain_score(amount: 2, casing: "lower") }
measured_wellbeing_scores = Statistics of { $username }'s measured { -wellbeing_score(amount: 2, casing: "lower") }
measured_pain_locations = { $username }'s measured { -pain_location(amount: 2, casing: "lower") }
player_accounts_description = The { -player-account(amount: 2, casing: "lower") } of this { -user(casing: "lower") }
brain_level = Brain level
nerve_level = Nerve level
nerve_highscore = Nerve highscore
chapters_played = { -game_chapter(amount: 2, casing: "upper") } played
last_played = Last played
access = Has access
no_access_row_description = This { -user(casing: "lower") } has no access to { -brand-name } because the { -practice(casing: “lower”) } does not have an active { -subscription(casing: "lower") }
access_row_description = This { -user(casing: "lower") } has access to { -brand-name } via their { -practice(casing: "lower") } because the { -practice(casing: “lower”) } has an active { -subscription(casing: "lower") }
access_description = This { -user(casing: "lower") } has (no) access to { -brand-name } via their { -practice(casing: "lower") }
contact = Contact
contact_description = This { -user(casing: "lower") } is a contact of this { -practice(casing: "lower") }
roles_description = The { -role(amount: 2, casing: "lower") } of this { -user(casing: "lower") }
stripe_account_description = The associated { -stripe_account(casing: "lower") }
stripe_id = { -stripe } id
subscriptions_for_model = The associated { -subscription(amount: 2, casing: "lower") } for this { -user(casing: "lower") }
subscription_holder = Subscription holder
practice_subscription_holder = This { -user(casing: "lower") } is the { -subscription(casing: "lower") } holder within this { -practice(casing: "lower") }
error_message = Something went wrong. please contact support at { -support_link }
practices_count = { -practice(amount: 2, casing: "upper") } amount
active_subscription = Active { -subscription(casing: "lower") }
active_subscriptions = Amount of active { -subscription(amount: 2, casing: "lower") }
active_subscriptions_description = The amount of { -practice(amount: 2, casing: "lower") } within this { -partner(casing: "lower") } with an active { -subscription(casing: "lower") }
practice_id = { practice }
practice_id_description = The { -practice(casing: "lower") } where this { -subscription(casing: "lower") } belongs to
user_id = { user }
user_id_description = This { -user(casing: "lower") } where this { -subscription(casing: "lower") } belongs to
has_finished_introduction = Finished introduction
last_brain_pattern_length = Last brain pattern length
users_count = Amount of { -user(amount: 2, casing: "lower") }
licensing = { -license }
licensing_description = { -license } information
licensing_only_practices = Only { -practice(casing: "lower") } { -subscription(amount: 2, casing: "lower") } use { -license(amount: 2, casing: "lower") }
licenses_per_period = Amount of { -license(amount: 2, casing: "lower") } per period
licenses_redeemed_in_current_period = Amount of used { -license(amount: 2, casing: "lower") } in this period
licenses_available_in_current_period = Amount of { -license(amount: 2, casing: "lower") } available
licenses_available_in_current_period_description = The amount of available { -license(amount: 2, casing: "lower") } for this { -practice(casing: "lower") } during the current period
current_licensing_period = Current period
licenses_reset_interval = { -license } reset period
licenses_reset_interval_description = The interval after which the { -license(amount: 2, casing: "lower") } reset
partner_id = { partner }
partner_description = The { -partner(casing: "lower") } where this { -practice(casing: "lower") } belongs to
overview = Overview
unlimited = Unlimited
year = Yearly
month = Monthly
amount_licenses = Amount of { -license(amount: 2, casing: "lower") }
amount_licenses_description = The amount of available { -license(amount: 2, casing: "lower")} per { licenses_reset_interval }. Necessary for monthly or yearly reset periods
logged_in_player = Logged in { -user(casing: "lower") }
login_logout = Log in / out
has_active_subscription = Has an active { -subscription(casing: "lower") }
game_version = Reducept version
game_api_version = Game API
given_device_name = Given name
device_name = Original name
device_unique_identifier = Unique identification number
specification = Specification
device_specification = { -device } specification
supports_audio = Supports audio
manual = Manual
trial = Trial period
stripe = { -stripe }
ecurring = { -ecurring }
manual_only = Manual only
practice_subscription_description = The { -practice(casing: "lower") } that uses the { -subscription(casing: "lower") }
provider_type_user_description = The desired { -subscription_type(casing: "lower") } for this { -user(casing: "lower") }. currently only manual and trial { -subscription(amount: 2, casing: "lower") } are available
provider_type_practice_description = The desired { -subscription_type(casing: "lower") } for this { -practice(casing: "lower") }.
user_subscription_description = The { -user(casing: "lower") } that uses the { -subscription(casing: "lower") }
provider_type_information = { -subscription_type } information
mandate_accepted_date = Mandate accepted at
admin_since = { -administrator } since
filter_value = Filter value
contains_filter_label = Contains
no_data_graph = There is no data to display
deleted = deleted
close = Schließen
access_period_user_deleted_tooltip = The { -user(casing: "lower") } has been deleted at some point. The redeemed { -access_period(casing: "lower") } can not be redeemed again
device_deleted = { -device } { deleted }
comments_for_model = The associated { -comment(amount: 2, casing: "lower") } for this { $model }
licensing_only_professionals = Only { -practice(casing: "lower") } { -subscription(amount: 2, casing: "lower") } use { -license(amount: 2, casing: "lower") }
responded_at = Accepted
responded_at_date = Accepted at
invited_user = Invited { -user(casing: "lower") }
invited_user_email = Invited { -user(casing: "lower") } { -email(casing: "lower") }
open_professional_invites = Open { -professional_invite(amount: 2, casing: "lower") }
resend_professional_invite = Resend { -professional_invite(casing: "lower") }
last_sent_at = Last sent at
reset-password-dialog-description = Do you want to send this { -user(casing: "lower" )} an email containing a password reset link?
reset-password-email-sent = Password reset email has been sent
reset-password-tooltip-user-has-no-password = { -user } has no password set yet
patient_never_had_access = This { -patient(casing: "lower") } has never had access
access_expired_on = Access was expired on { $expiredOn }
demo_account_access = This { -patient(casing: "lower") } has access because it's a demo account

## Log types

create = Create
token_refresh = Token refresh
created_from_stripe_webhook = Created from { -stripe } webhook
synchronization = Synchonization
google_analytics = { -google_analytics }
bad_request = Bad request
password_reset = { -password } reset
subscription = subscription
mailchimp = { -mailchimp }
access_period = { -access_period }
resend_confirm_account = Resend confirmation mail
account_confirmed = Account confirmed
password_changed = { -password } changed
detached_from_professional = Detached from { -practice(casing: "lower") }
role_added = { -role } added
role_removed = { -role } removed
professional_invite_accepted = Accepted invite from { -practice(casing: "lower") }
professional_invite_sent = Invite sent to { -user(casing: "lower") }
education_module_used = Education module used

## Stripe subscription status

canceled = Canceled
incomplete = Incomplete
incomplete_expired = Incomplete (expired)
past_due = Past due
trialing = Trial period
unpaid = Unpaid

## Ecurring subscription status

cancelled = Canceled
paused = Paused
unverified = Unverified
suspended = Suspended

## MailChimp member status

subscribed = Subscribed
unsubscribed = Unsubscribed
cleaned = Cleaned
pending = Pending
transactional = Transactional

## Registration origin

b2b2c = B2B2C
website = Website
unknown = Unknown

## Form helper texts

required = Required
invalid-email-format = Invalid e-mail format

add-admin-help-text =
  Search for { users } by typing in their information in the 'Search' box. Assign the
  'Admin' { -role(casing: "lower") } to the selected { -user(casing: "lower") }(s).
delete-admin-help-text =
  Search for { users } by typing in their information in the 'Search' box. Delete the
  'Admin' { -role(casing: "lower") } from the selected { -user(casing: "lower") }(s).

delete_group_confirm = Are you sure you want to delete this { -group(casing: "lower") }? { -user(amount: 2) } inside this { -group(casing: "lower") } will NOT be deleted
detach_staff_confirm = Are you sure you want to delete this staff member? The { -user(casing: "lower") } will NOT be deleted. Note that a { -practice(casing: "lower") } should have at least have 1 owner at all times!
delete_patient_confirm = Are you sure you want to delete this { -patient(casing: "lower") }? The { -user(casing: "lower") } will not be deleted if this user has its own { -email(casing: "lower") } and { -password(casing: "lower") } and has a verified account
delete_group_success = { -group } successfully deleted
detach_staff_success = Staff member successfully detached from the { -practice(casing: "lower") }
delete_patient_success = { -patient } successfully deleted
device_user_logged_in = { -user } succesfully logged in
device_user_logged_out = { -user } succesfully logged out
delete_device_confirm = Are you sure you want to delete this { -device(casing: "lower") }? Any logged in { -user(amount: 2, casing: "lower") } will be logged out.
delete_device_success = { -device } successfully deleted
no_email_set = No { -email(casing: "lower") } set
extend_access_confirm = You are about to extend this { -user(casing: "lower") }'s access period by 3 months
extend_access_success = { -access_period } extended
licenses_left_amount = You currently have { $amount } { -license(amount: 2, casing: "lower") } within the current period
datepicker_from_to = Date from / to
no_licenses = No { -license(amount: 2, casing: "lower") } available within this period
no_licenses_starts_at = No { -license(amount: 2, casing: "lower") } available within this period. Your next period starts at { $start }
no_subscription_found = No { -subscription(casing: "lower") } found. You need a subscription before you can give access to a { -patient(casing: "lower") }

# Pages

## Login page

dashboard_welcome = Hello { $username }
dashboard_body_newsletter = newsletter
dashboard_body_newsletter_url = https://www.reducept.com/blog/reducept-dashboard-update-brand-new-look
dashboard_body = We’ve heard your feedback over the past year and have been hard at work making our technical infrastructure better than ever. You can read the release notes in our
sign-in = Sign in
login = Login
logout = Logout
login-with-your-account = Login with your { -brand-name } account
forgot-password = Forgot { -password(casing: "lower") }?
use_tfa_recovery_code = Use 2FA recovery code
hide_tfa_recovery_code = Hide 2FA recovery code
incorrect_credentials = Incorrect email address or password, please try again. Forgot your password? Click the '{ forgot-password }' button below.
login_incorrect_role_error = The dashboard can only be used by { -brand-name } { -administrator(amount: 2, casing: "lower") }, { -partner(amount: 2, casing: "lower") }, and medical professionals. If you are a { -patient(casing: "lower") } at a { -practice(casing: "lower") }, please contact the { -practice(casing: "lower") } { -owner(casing: "lower") }. If you are not a { -patient(casing: "lower") } and you are sure you should be able to log in, please send us an email at { -support_link }

## Reset password page

reset-password = Reset { -password(casing: "lower") }
reset-password-message = Enter your new { -password(casing: "lower") }
send-reset-password-link = Send { -password(casing: "lower") } reset link
back-to-sign-in = Back to sign in
reset-password-message = Enter your { -email(casing: "lower") } and we will send you a { -password(casing: "lower") } reset link.
reset-password-sent-message = We've sent you an { -email(casing: "lower") } with instructions on how to reset your { -password(casing: "lower") }.
password_not_match = The passwords do not match

## Register page

set-password = Set your { -password(casing: "lower") }
set-password-message = Enter your new { -password(casing: "lower") }
invalid_token_part_1 = Invalid confirmation token
invalid_token_part_2 = If you got here by clicking on the link in your email, please contact support.
invalid_token_part_3 = See
register_submit_general_error = Something went wrong. You've probably already registered your account and set a password. If this is not the case, please contact support at { -support_link }

## Account confirmed page

account_confirmed_part_1 = Hey there,
account_confirmed_part_2 = You are now all set to use { -brand-name }!
account_confirmed_part_3 = If you are a { -therapist(casing: "lower") } or { -practice(casing: "lower") } { -owner(casing: "lower") }, please log into your dashboard to get started.
account_confirmed_part_4 = If you are a { -patient(casing: "lower") }, you can now use your log-in credentials in { -brand-name } on your VR headset, or your mobile phone. The { -brand-name } app is available on the Pico App Store, the Apple App Store, and the Google Play Store for Android.
account_confirmed_part_5 = If you have any problems, please do not hesitate to contact us at { -support_link }
account_confirmed_back_to_sign_in = Back to sign in

## Professional invite pages

professional-invite__page-title = Invite a { -user(casing: "lower") }
professional-invite__email-description = Enter the { -email(casing: "lower") } of a { -user(casing: "lower") } that already has a { -brand-name } account. The { -user(casing: "lower") } will receive an { -email(casing: "lower") } that allows them to join your { -practice(casing: "lower") }
professional-invite__role-description = Select the { -role(casing: "lower") } that will be assigned to the { -user(casing: "lower") }. Only the { -practice(casing: "lower") } { -owner(casing: "lower") } can invite { -user(casing: "lower", amount: 2) } to become a { -practice(casing: "lower") } { -owner(casing: "lower") }.
professional-invite__accepted-part-1 = Invite succesfully accepted. You are now part of the { -practice(casing: "lower") }!
professional-invite__accepted-part-2 = This window can be safely closed
professional-invite__question = You are invited by <bold_text>{ $professional_name }</bold_text> to become a <bold_text>{ $role }</bold_text> at their practice. Do you want to accept the invite?
professional-invite__resend-not-allowed-tooltip = You can only resend a { -professional_invite(casing: "lower") } once every 5 minutes

## Navbar

administration = Administration
navbar_license_info = { -license } info
navbar_license_available = Available: { $current_amount } / { $maximum_amount }
navbar_period_refreshes_at = Period end: { $date }
navbar_professional_information_error_message = Something went wrong while loading information about your { -practice(casing: "lower") }. Please contact support at { -support_link }

## Professional education tab

education = Education
education_module_usage = Education module usage
education_module_used_count = Times used
education_module_last_used = Latest use

## Two-Factor authentication flow

scan_qr_code = Scan the QR code below using an Two-Factor authentication app, for example the Google Authenticator. Afterwards fill in the generated code below.
success = Success
tfa_enabled_success = Two factor authentication has been enabled
recovery_codes = Recovery codes
recovery_codes_explanation = These recovery codes can be used to recover your account in case you've lost access to your 2FA app
recovery_codes_store_safely = <bold_text>Store them somewhere safe!</bold_text>
check-tfa-app = Please fill in the code from your authenticator app
use_tfa_recovery_code_explanation = Use one of your saved 2FA recovery codes
tfa_should_be_enabled = { -administrator(amount: 2, casing: "upper" ) } are required to have 2FA enabled in order to use the dashboard

`);
