import React from 'react';

import { Box, Card, CardContent } from '@material-ui/core';
import { SubmitHandler } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';

import Button from 'components/Button';
import DetailPage from 'components/DetailPage';
import LinkButton from 'components/LinkButton';
import TextField from 'components/mui/TextField';
import { IUser } from 'core/user';
import { useReduceptForm } from 'hooks/useReduceptForm';
import useRequest from 'hooks/useRequest';
import useStateOrFetch from 'hooks/useStateOrFetch';
import { useLocalization } from 'services/localization/localization';
import { history, routeCreator, UserRouteParams } from 'services/routing';

import { setUser } from 'store/session/sessionActions';
import { isAdminSelector, userSelector } from 'store/session/sessionSelectors';
import { useTypedDispatch, useTypedSelector } from 'store/store';
import ErrorPage from 'pages/ErrorPage';
import LoadingPage from 'pages/LoadingPage';

type FetchResponse = IUser;
type Props = RouteComponentProps<UserRouteParams, any, FetchResponse>;

interface IDisableTwoFactorAuthPayload {
  password: string;
}

const DisableTwoFactorAuth: React.FC<Props> = (props) => {
  const { userId } = props.match.params;
  const dispatch = useTypedDispatch();
  const isAdmin = useTypedSelector(isAdminSelector);
  const authenticatedUser = useTypedSelector(userSelector);

  const request = useStateOrFetch<FetchResponse | null>(props.location.state, `/users/${userId}`);
  const deleteRequest = useRequest<null, never, IDisableTwoFactorAuthPayload>({
    url: `/users/${userId}/tfa`,
    method: 'DELETE',
  });

  const onSubmit: SubmitHandler<IDisableTwoFactorAuthPayload> = async (values) => {
    try {
      await deleteRequest.request({
        data: values,
      });
      dispatch(setUser({ ...user, has_tfa_enabled: false, recovery_codes: undefined }));
      history.push(routeCreator.ManageTwoFactorAuth(userId));
    } catch (error) {
      alert(getLocalizedString('error_message'));
      throw error;
    }
  };

  const {
    register,
    errors,
    handleSubmit,
    formState: { touched, isSubmitting },
  } = useReduceptForm<IDisableTwoFactorAuthPayload>(onSubmit, {});

  const { getLocalizedString } = useLocalization();

  if (request.loading && !request.data) {
    return <LoadingPage />;
  }

  if (!request.data) {
    return <ErrorPage />;
  }

  const user = request.data as FetchResponse;
  const isSelf = authenticatedUser.id === user.id;

  if (!user.has_tfa_enabled) {
    history.push(routeCreator.UserDetail(userId), user);
  }

  const pageTitle = getLocalizedString('disable_tfa');

  return (
    <DetailPage title={pageTitle} subtitle={user.name} maxWidth='xs'>
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit}>
            {(!isAdmin || isSelf) && (
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password?.message}
                label='Password'
                margin='normal'
                translationKey='password'
                inputRef={register({ required: getLocalizedString('required') })}
                type='password'
                autoComplete='password'
                variant='outlined'
                disabled={isSubmitting}
              />
            )}

            <Box my={2}>
              <Button
                translationKey='disable_tfa'
                color='primary'
                loading={isSubmitting}
                fullWidth
                size='large'
                type='submit'
                variant='contained'
              >
                Disable 2FA
              </Button>
            </Box>
            <Box my={2} display='flex' justifyContent='center'>
              <LinkButton
                translationKey='back'
                to={routeCreator.ManageTwoFactorAuth(user.id)}
                color='secondary'
                disabled={isSubmitting}
                size='small'
                type='button'
                variant='text'
              >
                Back
              </LinkButton>
            </Box>
          </form>
        </CardContent>
      </Card>
    </DetailPage>
  );
};

export default DisableTwoFactorAuth;
