import React from 'react';

import { Tooltip } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import { DateTime } from 'luxon';

import LuxonDate from 'components/LuxonDate';
import { renderDataGridCheckCell } from 'components/mui/DataGrid/DataGridCheckCell';
import { LoadedUserRole } from 'core/backend';
import { createDateTooltipTitle, GridColumn, renderDateCell } from 'utils/mui';

import { userTableColumns, UserTableRowData } from './usersTable';

/**
 * The type of data in each table row.
 */
export type AdminTableRowData = UserTableRowData & LoadedUserRole;

/**
 * Cell renderer to check if this user is a patient at any professional.
 *
 * @param {GridCellParams} params
 *   Grid cell parameters.
 */
const renderRoleAddedAtCell: GridColDef['renderCell'] = (params) => {
  const row = params.row as AdminTableRowData;

  return (
    <Tooltip title={createDateTooltipTitle(row.user_role.role_added_at)}>
      <span>
        <LuxonDate date={row.user_role.role_added_at} format={DateTime.DATE_SHORT} />
      </span>
    </Tooltip>
  );
};

/**
 * Display all but a few userTable columns.
 *
 * Also add an extra column, and place the 'last_activity_at' column to the back.
 */
export const administratorTableColumns: GridColumn<AdminTableRowData>[] = [
  ...userTableColumns.filter(
    (column) =>
      [
        'name',
        'first_name',
        'last_name',
        'email',
        'patient',
        'has_tfa_enabled',
        'professional',
      ].indexOf(column.field) >= 0,
  ),
  {
    field: `admin_since`,
    headerName: 'Admin since',
    flex: 0.5,
    renderCell: renderRoleAddedAtCell,
    filterable: false,
  },
  {
    field: 'last_activity_at',
    headerName: 'Last activity',
    flex: 1,
    renderCell: renderDateCell(DateTime.DATETIME_SHORT),
    filterable: false,
  },
];
