import { createBrowserHistory } from 'history';

import { Role } from './roles';

export const history = createBrowserHistory();

export interface ProtectedRoute {
  path: string;
  roles: Role[];
}

interface IDetailRoutes {
  UserDetail: ProtectedRoute;
  EditUser: ProtectedRoute;
  ChangeUserPassword: ProtectedRoute;

  ManageTwoFactorAuth: ProtectedRoute;
  EnableTwoFactorAuth: ProtectedRoute;
  DisableTwoFactorAuth: ProtectedRoute;
  ConfirmTwoFactorAuth: ProtectedRoute;

  EditSubscription: ProtectedRoute;
  SubscriptionDetail: ProtectedRoute;

  EditProfessional: ProtectedRoute;
  ProfessionalDetail: ProtectedRoute;
  ProfessionalGroupDetail: ProtectedRoute;
  ProfessionalAccessPeriodDetail: ProtectedRoute;
  ProfessionalPatients: ProtectedRoute;
  ProfessionalProfessionalGroups: ProtectedRoute;
  ProfessionalStaff: ProtectedRoute;
  ProfessionalDevices: ProtectedRoute;
  ProfessionalProfessionalInvites: ProtectedRoute;
  CreateStaff: ProtectedRoute;
  InviteStaff: ProtectedRoute;
  EditStaff: ProtectedRoute;
  StaffDetail: ProtectedRoute;
  CreateProfessionalGroup: ProtectedRoute;
  EditProfessionalGroup: ProtectedRoute;

  CreatePatient: ProtectedRoute;
  InvitePatient: ProtectedRoute;
  EditPatient: ProtectedRoute;
  PatientDetail: ProtectedRoute;
  ChangePatientPassword: ProtectedRoute;

  EditDevice: ProtectedRoute;
  DeviceDetail: ProtectedRoute;

  OrganizationDetail: ProtectedRoute;
  PlayerAccountDetail: ProtectedRoute;
  GameLoginDetail: ProtectedRoute;
  GameChapterDetail: ProtectedRoute;
  RoleDetail: ProtectedRoute;
  TeamleaderProductAttach: ProtectedRoute;
  TeamleaderCompanyAttachProfessional: ProtectedRoute;
  TeamleaderCompanyAttachOrganization: ProtectedRoute;
  TeamleaderCompanyManageOrganizationSubscriptions: ProtectedRoute;

  ProductDetail: ProtectedRoute;
  EditProduct: ProtectedRoute;
}

interface IRoutes {
  Dashboard: string;
  Login: string;
  Register: string;
  RegisterConfirm: string;
  PasswordReset: string;
  PasswordResetConfirm: string;
  ProfessionalInviteConfirm: string;
  Products: ProtectedRoute;
  Users: ProtectedRoute;
  Professionals: ProtectedRoute;
  Organizations: ProtectedRoute;
  PlayerAccounts: ProtectedRoute;
  Devices: ProtectedRoute;
  GameLogins: ProtectedRoute;
  GameChapters: ProtectedRoute;
  Subscriptions: ProtectedRoute;
  ProfessionalGroups: ProtectedRoute;
  Roles: ProtectedRoute;
  Administrators: ProtectedRoute;
  ProfessionalInvites: ProtectedRoute;

  CreateUser: ProtectedRoute;
  CreateProfessional: ProtectedRoute;
  CreateSubscription: ProtectedRoute;
  CreateProduct: ProtectedRoute;

  TeamleaderStatus: ProtectedRoute;
  TeamleaderProducts: ProtectedRoute;
  TeamleaderCompanies: ProtectedRoute;
  TeamleaderOrganizations: ProtectedRoute;
  TeamleaderSubscriptions: ProtectedRoute;
}

type RouteCreator<R extends object> = {
  [key in keyof R]: (...args: (number | string)[]) => string;
};

/**
 * Route parameters for routes containing a professional id.
 */
export interface ProfessionalRouteParams {
  professionalId: string;
}

/**
 * Route parameters for routes containing a professional id.
 */
export interface ProductRouteParams {
  productId: string;
}

/**
 * Route parameters for routes containing a user id.
 */
export interface UserRouteParams {
  userId: string;
}

/**
 * Route parameters for routes containing a subscription id.
 */
export interface SubscriptionRouteParams {
  subscriptionId: string;
}

/**
 * Route parameters for routes containing a device id.
 */
export interface DeviceRouteParams {
  deviceId: string;
}

/**
 * Route parameters for routes containing a professionalGroup id.
 */
export interface ProfessionalGroupRouteParams {
  professionalGroupId: string;
}

export const routes: IRoutes & IDetailRoutes = {
  Dashboard: '/',
  Login: '/login',
  Register: '/register',
  RegisterConfirm: '/register/confirm',
  PasswordReset: '/password/reset',
  PasswordResetConfirm: '/password/confirm/:token',
  ProfessionalInviteConfirm: '/invite/confirm',
  Users: {
    path: '/users',
    roles: [Role.Admin],
  },
  CreateUser: {
    path: '/users/new',
    roles: [Role.Admin],
  },
  EditUser: {
    path: '/users/:userId/edit',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  ChangeUserPassword: {
    path: '/users/:userId/change-password',
    roles: [Role.Admin],
  },
  ManageTwoFactorAuth: {
    path: '/users/:userId/two-factor-auth',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  EnableTwoFactorAuth: {
    path: '/users/:userId/enable-two-factor-auth',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  DisableTwoFactorAuth: {
    path: '/users/:userId/disable-two-factor-auth',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  ConfirmTwoFactorAuth: {
    path: '/users/:userId/confirm-two-factor-auth',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  ChangePatientPassword: {
    path: '/practices/:professionalId/users/:userId/change-password',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  ProfessionalPatients: {
    path: '/practices/:professionalId/patients',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  CreatePatient: {
    path: '/practices/:professionalId/patients/new',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  InvitePatient: {
    path: '/practices/:professionalId/patients/invite',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  PatientDetail: {
    path: '/practices/:professionalId/patients/:userId',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  EditPatient: {
    path: '/practices/:professionalId/patients/:userId/edit',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  ProfessionalProfessionalGroups: {
    path: '/practices/:professionalId/groups',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  ProfessionalStaff: {
    path: '/practices/:professionalId/staff',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  CreateStaff: {
    path: '/practices/:professionalId/staff/new',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  InviteStaff: {
    path: '/practices/:professionalId/staff/invite',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  EditStaff: {
    path: '/practices/:professionalId/staff/:userId/edit',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  StaffDetail: {
    path: '/practices/:professionalId/staff/:userId',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  ProfessionalDevices: {
    path: '/practices/:professionalId/devices',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  ProfessionalProfessionalInvites: {
    path: '/practices/:professionalId/invites',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  UserDetail: {
    path: '/users/:userId',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  Professionals: {
    path: '/practices',
    roles: [Role.Admin],
  },
  CreateProfessional: {
    path: '/practices/new',
    roles: [Role.Admin],
  },
  EditProfessional: {
    path: '/practices/:professionalId/edit',
    roles: [Role.Admin],
  },
  ProfessionalDetail: {
    path: '/practices/:professionalId',
    roles: [Role.Admin],
  },
  Organizations: {
    path: '/partners',
    roles: [Role.Admin],
  },
  OrganizationDetail: {
    path: '/partners/:organizationId',
    roles: [Role.Admin],
  },
  PlayerAccounts: {
    path: '/player-accounts',
    roles: [Role.Admin],
  },
  PlayerAccountDetail: {
    path: '/player-accounts/:playerAccountId',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  Devices: {
    path: '/devices',
    roles: [Role.Admin],
  },
  EditDevice: {
    path: '/devices/:deviceId/edit',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  DeviceDetail: {
    path: '/devices/:deviceId',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  GameLogins: {
    path: '/game-logins',
    roles: [Role.Admin],
  },
  GameLoginDetail: {
    path: '/game-logins/:gameLoginId',
    roles: [Role.Admin],
  },
  GameChapters: {
    path: '/game-chapters',
    roles: [Role.Admin],
  },
  GameChapterDetail: {
    path: '/game-chapters/:gameChapterId',
    roles: [Role.Admin],
  },
  Subscriptions: {
    path: '/subscriptions',
    roles: [Role.Admin],
  },
  CreateSubscription: {
    path: '/subscriptions/new',
    roles: [Role.Admin],
  },
  Products: {
    path: '/products',
    roles: [Role.Admin],
  },
  CreateProduct: {
    path: '/products/new',
    roles: [Role.Admin],
  },
  EditProduct: {
    path: '/products/:productId/edit',
    roles: [Role.Admin],
  },
  ProductDetail: {
    path: '/products/:productId',
    roles: [Role.Admin],
  },
  EditSubscription: {
    path: '/subscriptions/:subscriptionId/edit',
    roles: [Role.Admin],
  },
  SubscriptionDetail: {
    path: '/subscriptions/:subscriptionId',
    roles: [Role.Admin],
  },
  ProfessionalGroups: {
    path: '/groups',
    roles: [Role.Admin],
  },
  ProfessionalGroupDetail: {
    path: '/practices/:professionalId/groups/:professionalGroupId',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  CreateProfessionalGroup: {
    path: '/practices/:professionalId/groups/new',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  EditProfessionalGroup: {
    path: '/practices/:professionalId/groups/:professionalGroupId/edit',
    roles: [Role.Admin, Role.Therapist, Role.Owner],
  },
  ProfessionalAccessPeriodDetail: {
    path: '/access-periods/:professionalAccessPeriodId',
    roles: [Role.Admin],
  },
  Roles: {
    path: '/roles',
    roles: [Role.Admin],
  },
  RoleDetail: {
    path: '/roles/:roleId',
    roles: [Role.Admin],
  },
  Administrators: {
    path: '/administrators',
    roles: [Role.Admin],
  },
  ProfessionalInvites: {
    path: '/invites',
    roles: [Role.Admin],
  },
  TeamleaderStatus: {
    path: '/teamleader/status',
    roles: [Role.Admin],
  },
  TeamleaderProducts: {
    path: '/teamleader/products',
    roles: [Role.Admin],
  },
  TeamleaderProductAttach: {
    path: '/teamleader/products/:teamleaderProductId/attach',
    roles: [Role.Admin],
  },
  TeamleaderCompanies: {
    path: '/teamleader/companies',
    roles: [Role.Admin],
  },
  TeamleaderOrganizations: {
    path: '/teamleader/partners',
    roles: [Role.Admin],
  },
  TeamleaderCompanyAttachProfessional: {
    path: '/teamleader/companies/:teamleaderCompanyId/attach-practice',
    roles: [Role.Admin],
  },
  TeamleaderCompanyAttachOrganization: {
    path: '/teamleader/companies/:teamleaderCompanyId/attach-partner',
    roles: [Role.Admin],
  },
  TeamleaderCompanyManageOrganizationSubscriptions: {
    path: '/teamleader/partners/:teamleaderCompanyId/manage-subscriptions',
    roles: [Role.Admin],
  },
  TeamleaderSubscriptions: {
    path: '/teamleader/subscriptions',
    roles: [Role.Admin],
  },
};

export const routeCreator: RouteCreator<IDetailRoutes> = {
  EditUser: (userId) => `/users/${userId}/edit`,
  UserDetail: (userId) => `/users/${userId}`,
  EditProfessional: (professionalId) => `/practices/${professionalId}/edit`,
  ProfessionalDetail: (professionalId) => `/practices/${professionalId}`,
  ProfessionalPatients: (professionalId) => `/practices/${professionalId}/patients`,
  CreatePatient: (professionalId) => `/practices/${professionalId}/patients/new`,
  InvitePatient: (professionalId) => `/practices/${professionalId}/patients/invite`,
  EditPatient: (professionalId, userId) => `/practices/${professionalId}/patients/${userId}/edit`,
  PatientDetail: (professionalId, userId) => `/practices/${professionalId}/patients/${userId}`,
  ChangeUserPassword: (userId) => `/users/${userId}/change-password`,
  ManageTwoFactorAuth: (userId) => `/users/${userId}/two-factor-auth`,
  EnableTwoFactorAuth: (userId) => `/users/${userId}/enable-two-factor-auth`,
  DisableTwoFactorAuth: (userId) => `/users/${userId}/disable-two-factor-auth`,
  ConfirmTwoFactorAuth: (userId) => `/users/${userId}/confirm-two-factor-auth`,
  ChangePatientPassword: (professionalId, userId) =>
    `/practices/${professionalId}/users/${userId}/change-password`,
  ProfessionalProfessionalGroups: (professionalId) => `/practices/${professionalId}/groups`,
  ProfessionalStaff: (professionalId) => `/practices/${professionalId}/staff`,
  CreateStaff: (professionalId) => `/practices/${professionalId}/staff/new`,
  InviteStaff: (professionalId) => `/practices/${professionalId}/staff/invite`,
  EditStaff: (professionalId, userId) => `/practices/${professionalId}/staff/${userId}/edit`,
  StaffDetail: (professionalId, userId) => `/practices/${professionalId}/staff/${userId}`,
  ProfessionalDevices: (professionalId) => `/practices/${professionalId}/devices`,
  OrganizationDetail: (organizationId) => `/partners/${organizationId}`,
  PlayerAccountDetail: (playerAccountId) => `/player-accounts/${playerAccountId}`,
  EditDevice: (deviceId) => `/devices/${deviceId}/edit`,
  DeviceDetail: (deviceId) => `/devices/${deviceId}`,
  GameLoginDetail: (gameLoginId) => `/game-logins/${gameLoginId}`,
  GameChapterDetail: (gameChapterId) => `/game-chapters/${gameChapterId}`,
  SubscriptionDetail: (subscriptionId) => `/subscriptions/${subscriptionId}`,
  EditSubscription: (subscriptionId) => `/subscriptions/${subscriptionId}/edit`,
  ProfessionalGroupDetail: (professionalId, professionalGroupId) =>
    `/practices/${professionalId}/groups/${professionalGroupId}`,
  CreateProfessionalGroup: (professionalId) => `/practices/${professionalId}/groups/new`,
  EditProfessionalGroup: (professionalId, professionalGroupId) =>
    `/practices/${professionalId}/groups/${professionalGroupId}/edit`,
  ProfessionalAccessPeriodDetail: (professionalAccessPeriodId) =>
    `/access-periods/${professionalAccessPeriodId}`,
  RoleDetail: (roleId) => `/roles/${roleId}`,
  ProfessionalProfessionalInvites: (professionalId) => `/practices/${professionalId}/invites`,
  TeamleaderProductAttach: (teamleaderProductId) =>
    `/teamleader/products/${teamleaderProductId}/attach`,
  TeamleaderCompanyAttachProfessional: (teamleaderCompanyId) =>
    `/teamleader/companies/${teamleaderCompanyId}/attach-practice`,
  TeamleaderCompanyAttachOrganization: (teamleaderCompanyId) =>
    `/teamleader/companies/${teamleaderCompanyId}/attach-partner`,
  TeamleaderCompanyManageOrganizationSubscriptions: (teamleaderCompanyId) =>
    `/teamleader/partners/${teamleaderCompanyId}/manage-subscriptions`,
  ProductDetail: (productId) => `/products/${productId}`,
  EditProduct: (productId) => `/products/${productId}/edit`,
};
