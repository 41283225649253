import React from 'react';

import { LinearProgress } from '@material-ui/core';
import { Redirect, Route, RouteProps } from 'react-router';

import { ProtectedRoute, routeCreator, routes } from 'services/routing';
import {
  hasTfaEnabledSelector,
  isAdminSelector,
  loggedInSelector,
  matchRoles,
  onInitialLoadSelector,
} from 'store/session/sessionSelectors';
import { useTypedSelector } from 'store/store';

type Props = RouteProps & Partial<ProtectedRoute>;

const PrivateRoute: React.FC<Props> = ({ roles = [], component: Component, ...props }) => {
  const appState = useTypedSelector((state) => state);
  const loggedIn = useTypedSelector(loggedInSelector);
  const onInitialLoad = useTypedSelector(onInitialLoadSelector);
  const hasTfaEnabled = useTypedSelector(hasTfaEnabledSelector);
  const isAdmin = useTypedSelector(isAdminSelector);

  if (onInitialLoad) {
    return <LinearProgress style={{ height: 0.5 }} />;
  }

  if (!loggedIn) {
    return <Redirect to={routes.Login} />;
  }

  const insideTfaModule = [
    routes.ManageTwoFactorAuth.path,
    routes.EnableTwoFactorAuth.path,
    routes.ConfirmTwoFactorAuth.path,
    routes.DisableTwoFactorAuth.path,
  ].includes(props.path ?? '');
  // Admins should always have 2FA enabled
  if (!hasTfaEnabled && isAdmin && !insideTfaModule) {
    return <Redirect to={routeCreator.ManageTwoFactorAuth(appState.session.user!.id)} />;
  }

  if (!Component) {
    return null;
  }

  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (roles.length === 0 || matchRoles(roles)(appState)) {
          return <Component {...routeProps} />;
        }

        return <Redirect to={routes.Dashboard} />;
      }}
    />
  );
};

export default PrivateRoute;
